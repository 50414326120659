import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";

import { MobileMenu } from "./MobileMenu";
// import { FlyoutMenu } from "./FlyoutMenu";
import logoCompact from "../assets/images/logo-compact-01.png";
import whiteChicken from "../assets/images/white-chicken.png";

interface HeaderProps {
  showModal(): void;
}

export const Header = ({ showModal }: HeaderProps) => {
  // const [showFlyoutMenu, setShowFlyoutMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      <div className="fixed w-full bg-white z-20">
        <div className="mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center border-b-2 border-gray-100 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1 mx-6 my-4">
              <ScrollLink
                className="flex-none cursor-pointer"
                to="hero"
                smooth
                offset={-110}
              >
                <span className="sr-only">Allegra Music</span>
                <img
                  className="h-20"
                  src={logoCompact}
                  alt="Allegra Music Logo"
                />
              </ScrollLink>
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                onClick={() => setShowMobileMenu(true)}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden md:flex space-x-10">
              {/* <div className="relative flex-none lg:hidden">
                <button
                  type="button"
                  onClick={() => setShowFlyoutMenu((prevState) => !prevState)}
                  className="group bg-white rounded-md text-gray-500 inline-flex items-center text-sm font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  <span>What We Do</span>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <FlyoutMenu show={showFlyoutMenu} />
              </div> */}
              <ScrollLink
                to="about"
                smooth
                offset={-150}
                className="text-sm leading-7 font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
              >
                About
              </ScrollLink>
              <ScrollLink
                to="options"
                smooth
                offset={-100}
                className="text-sm leading-7 font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
              >
                Lessons
              </ScrollLink>

              <ScrollLink
                to="contact"
                smooth
                offset={-100}
                className="text-sm leading-7 font-medium text-gray-500 hover:text-gray-900 cursor-pointer"
              >
                Contact
              </ScrollLink>
            </nav>
            <div
              className={`hidden md:flex items-center justify-end md:flex-1 lg:w-0 flex-none`}
            >
              {/* <button
                onClick={showModal}
                className="px-8 ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-green-600 bg-white hover:bg-gray-50 hover:text-green-800 no-underline"
              > */}
              <ScrollLink
                to="contact"
                smooth
                offset={-100}
                className="px-8 ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md text-sm font-medium text-green-600 bg-white hover:bg-gray-50 hover:text-green-800 no-underline cursor-pointer"
              >
                <img
                  src={whiteChicken}
                  className="w-10 mr-6 shake-vertical shake-constant"
                  alt="White Chicken illustration"
                />
                <span className="font-bold">Book a Free Trial Lesson</span>
              </ScrollLink>
              {/* </button> */}
            </div>
          </div>
        </div>

        <MobileMenu show={showMobileMenu} hide={hideMobileMenu} />
      </div>
    </>
  );
};

import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { HomePageSection, Teacher } from "../../types/types";
import { richTextRenderOptions } from "../../utils/helpers";

export const Team = ({
  teachers,
  about,
}: {
  teachers: Teacher[];
  about?: HomePageSection;
}) => {
  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:pt-24 lg:pb-12">
        <div className="space-y-12 xl:gap-8 xl:space-y-0">
          <div className="space-y-5 sm:space-y-4 ">
            <h2
              id="about"
              className="text-3xl font-extrabold tracking-tight sm:text-4xl secondary-header text-center"
            >
              {about && about.title}
            </h2>
            <div className="text-xl text-gray-500 text-center max-w-3xl mx-auto">
              {about &&
                documentToReactComponents(
                  about.body.json,
                  richTextRenderOptions
                )}
            </div>
          </div>
          <div className="xl:col-span-2">
            <ul className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0">
              {teachers.map((teacher, index) => {
                return (
                  <li className="sm:py-8 sm:pt-20" key={index}>
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
                        <img
                          className="object-cover shadow-lg rounded-lg w-1/2 sm:w-full"
                          src={teacher.headshot.url}
                          alt={teacher.headshot.title}
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <div className="space-y-4 pt-2">
                          <div className="text-lg font-medium space-y-0">
                            <h3 className="text-gray-700 font-bold text-xl">
                              {teacher.name}
                            </h3>
                            <p className="text-green-600 text-base">
                              {teacher.title}
                            </p>
                          </div>
                          <div className="text-lg text-gray-500">
                            {teacher.description &&
                              documentToReactComponents(
                                teacher.description.json,
                                richTextRenderOptions
                              )}
                          </div>
                          <h4 className="font-bold text-gray-900">
                            Qualifications
                          </h4>
                          <ul className="space-y-2 text-gray-700">
                            {teacher.qualifications.map((qual) => {
                              return <li key={qual}>{qual}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

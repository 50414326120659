import { gql } from "@apollo/client";

import {
  HomePageSection,
  Teacher,
  LessonOption,
  Banner,
  ContactDetail,
} from "../../types/types";

export interface Data {
  homePageSectionCollection: {
    items: HomePageSection[];
  };

  teacherCollection: {
    teachers: Teacher[];
  };

  lessonOptionCollection: {
    options: LessonOption[];
  };

  bannerCollection: {
    banners: Banner[];
  };

  contactDetailCollection: {
    contactDetails: ContactDetail[];
  };
}

export const HOME_PAGE = gql`
  query HomePageSections {
    homePageSectionCollection {
      items {
        title
        body {
          json
        }
        slug
      }
    }

    teacherCollection {
      teachers: items {
        name
        headshot {
          title
          url
        }
        title
        qualifications
        description {
          json
        }
      }
    }

    lessonOptionCollection {
      options: items {
        title
        text
      }
    }

    bannerCollection {
      banners: items {
        image {
          title
          url
        }
        slug
      }
    }

    contactDetailCollection {
      contactDetails: items {
        name
        value
      }
    }
  }
`;

import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { HomePageSection, LessonOption } from "../../types/types";
import { richTextRenderOptions } from "../../utils/helpers";

export const Options = ({
  content,
  options,
}: {
  content?: HomePageSection;
  options: LessonOption[];
}) => {
  return (
    <div className="bg-white" id="options">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 secondary-header">
            {content?.title}
          </h2>
          <div className="mt-4 text-lg text-gray-500">
            {content &&
              documentToReactComponents(
                content.body.json,
                richTextRenderOptions
              )}
          </div>
        </div>
        <dl
          className={`mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-${options.length} sm:gap-x-6 sm:gap-y-12 lg:gap-x-8`}
        >
          {options.map((option) => {
            return (
              <div className="flex" key={option.title}>
                <svg
                  className="flex-shrink-0 h-6 w-6 text-green-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <div className="ml-3">
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    {option.title}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {option.text}
                  </dd>
                </div>
              </div>
            );
          })}
        </dl>
      </div>
    </div>
  );
};

import React from "react";

import logo from "../../../assets/images/Logo-notext-transparent.png";
// import logo from "../../../assets/images/Logo-name.png";

export const LogoSection = () => {
  return (
    <div className="-my-8">
      {/* <div className="my-1"> */}
      <img className="h-60 m-auto" src={logo} alt="Allegra Music Logo" />
    </div>
  );
};

import React, { useState } from "react";

import { Layout } from "./Layout";
import { Home } from "./Home";
import { RegisterModal } from "./RegisterModal";

function App() {
  const [displayRegisterModal, setDisplayRegisterModal] = useState(false);

  const showModal = () => setDisplayRegisterModal(true);

  return (
    <>
      {displayRegisterModal && (
        <RegisterModal hideModal={() => setDisplayRegisterModal(false)} />
      )}

      <Layout showModal={showModal}>
        <Home showModal={showModal} />
      </Layout>
    </>
  );
}

export default App;

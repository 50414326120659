import React from "react";

export const MusicNote = ({ className }: { className?: string }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      width="595.279px"
      height="841.891px"
      viewBox="0 0 595.279 841.891"
      enableBackground="new 0 0 595.279 841.891"
      className={`h-6 w-6 ${className}`}
    >
      <path
        d="M435.745,128.067c-34.526-40.479-83.935-64.291-136.915-64.291c-19.644,0-35.121,15.478-35.121,34.526v329.19
 c-10.715-3.571-22.025-5.953-33.931-5.953c-57.147,0-103.579,46.433-103.579,103.579s46.432,103.579,103.579,103.579
 c57.146,0,103.578-46.433,103.578-103.579V138.187c19.049,6.548,36.312,17.858,49.408,33.931
 c20.835,25.001,26.192,51.194,22.025,79.767c-1.786,10.12-7.738,29.169-14.882,49.409c-7.144,20.239,18.453,35.121,32.74,19.049
 C462.532,273.911,498.249,201.882,435.745,128.067z"
      />
    </svg>
  );
};

import React from "react";

import { BLOCKS, MARKS, Block, Inline } from "@contentful/rich-text-types";

export const richTextRenderOptions = {
  renderMark: {
    [MARKS.BOLD]: (text: React.ReactNode) => (
      <span className="font-bold">{text}</span>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => (
      <p className="my-4">{children}</p>
    ),
  },
};

import React from "react";

import { Header } from "./Header";
import { Footer } from "./Footer";

interface LayoutProps {
  children: React.ReactNode;
  showModal(): void;
}

export const Layout = ({ children, showModal }: LayoutProps) => {
  return (
    <>
      <Header showModal={showModal} />
      <div className="pt-28">{children}</div>
      <Footer />
    </>
  );
};

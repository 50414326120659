import React from "react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import "./styles/tailwind.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";

const httpLink = createHttpLink({
  uri: "https://graphql.contentful.com/content/v1/spaces/pht3u1mwjj9d",
});

const authLink = setContext((_, { headers }) => {
  // Publishing this because the API is read only and there is no sensitive data
  const token = "ZTkircttN2QfuISzsJwCYhZxU97SzLVkICrH29GFMSY";
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

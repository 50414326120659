import React from "react";
import { Link as ScrollLink } from "react-scroll";

import logoChicken from "../assets/images/Logo-long.png";

export const MobileMenu = ({
  show,
  hide,
}: {
  show: boolean;
  hide: () => void;
}) => {
  return (
    <div
      className={`${
        show ? "show scale-100" : "hide scale-95"
      } absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`}
    >
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center justify-between">
            <div>
              <img className="h-16 w-auto" src={logoChicken} alt="Workflow" />
            </div>
            <div className="-mr-2">
              <button
                type="button"
                onClick={() => hide()}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-6">
            <nav className="grid gap-y-8">
              <MobileLink
                to="about"
                text="About"
                hide={hide}
                icon={
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                    />
                  </svg>
                }
              />
              <MobileLink
                to="options"
                text="Lessons"
                hide={hide}
                icon={
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                    />
                  </svg>
                }
              />
              <MobileLink
                to="contact"
                text="Contact"
                hide={hide}
                icon={
                  <svg
                    className="flex-shrink-0 h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                }
              />
            </nav>
          </div>
        </div>
        <div className="py-6 px-5 space-y-6">
          <div>
            <ScrollLink
              to="contact"
              smooth
              offset={-150}
              onClick={() => {
                hide();
              }}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 cursor-pointer"
            >
              Book a Free Trial Lesson
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileLink = ({
  to,
  hide,
  text,
  icon,
}: {
  to: string;
  hide: () => void;
  text: string;
  icon: React.ReactNode;
}) => {
  return (
    <ScrollLink
      to={to}
      smooth
      offset={-150}
      onClick={() => {
        hide();
      }}
      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 no-underline cursor-pointer"
    >
      {icon}
      <span className="ml-3 text-lg font-medium text-gray-800">{text}</span>
    </ScrollLink>
  );
};

import React from "react";

export const PianoBanner = () => {
  return (
    <svg
      viewBox="0 0 3319.566 264.026"
      enableBackground="new 0 0 1803.536 264.026"
      className="my-4 piano-banner"
    >
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M106.495,148.016l-2.812,34.583l6.513,0.53L107,222.458c-10.93,0.787-22.296,0.945-34.186,0.354
	l8.627-43.259l7.768,1.869l6.307-34.944c4.388,0.69,8.869,1.252,13.444,1.696L106.495,148.016z"
      />
      <path
        className="piano-key"
        d="M1184.938,99.059c-0.287-0.2-0.571-0.411-0.856-0.611C1184.348,98.626,1184.633,98.826,1184.938,99.059z"
      />
      <path
        className="piano-key"
        d="M313.039,36.132c-0.839,0.462-1.677,0.915-2.517,1.388C311.254,37.09,312.104,36.628,313.039,36.132z"
      />
      <path
        className="piano-key"
        d="M958.531,110.51l21.98-7.173c-3.115,1.068-6.272,2.133-9.447,3.176C966.936,107.884,962.758,109.22,958.531,110.51z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M293.373,48.125c5.528-3.745,11.229-7.298,17.149-10.605l22.075,43.257l9.048-1.97l21.192,48.223l0,0.01
	c-9.35,2.092-18.458,4.806-27.385,7.973l-27.38-40.463l12.147-6.547L293.373,48.125z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M371.851,16.175l13.731,107.084l0,0.01c-7.768,0.816-15.335,2.105-22.745,3.771l0-0.01l-21.192-48.223
	l12.142-2.645l-13.173-52.254C350.37,20.517,360.744,17.864,371.851,16.175z"
      />
      <path
        className="piano-key"
        d="M851.641,127.765l17.322-0.299c-0.994,0.044-1.984,0.088-2.978,0.122C861.246,127.776,856.464,127.834,851.641,127.765z"
      />
      <path
        className="piano-key"
        d="M141.028,147.742c-2.507,0.369-5.048,0.651-7.636,0.857l0.75,34.936c0,0,4.679-0.973,9.064-1.906
	c3.621-0.758,7.041-1.478,7.519-1.596c1.062-0.268-2.581-33.544-2.581-33.544C145.804,146.986,143.439,147.408,141.028,147.742z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M557.818,112.181l28.716-55.842c4.663,1.659,9.335,3.33,14.006,5.021l-30.792,55.257l9.029,4.283
	l-25.373,45.571l0,0.01c-10.165-4.905-20.277-9.683-30.371-14.177l21.888-45.844L557.818,112.181z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M782.854,192.224l17.836-69.486c9.156,1.546,18.269,2.775,27.312,3.667L806.745,233.36
	c-17.519,1.812-36.149,2.348-56.1,1.194l14.599-47.572L782.854,192.224z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M656.462,213.398c-19.315-7.18-38.093-15.586-56.54-24.35l24.573-49.175l20.41,8.174l27.87-60.677
	c9.664,3.377,19.336,6.662,28.987,9.819L656.462,213.398z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M937.916,116.379l7.503,74.377c-12.4,5.829-25.3,11.65-38.885,17.07l-3.693-33.562l9.337-2.571
	l-6.732-48.265l-0.265,0.041C916.388,121.581,927.294,119.147,937.916,116.379z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M247.246,84.88l22.208,38.668l9.697-6.496l22.287,32.201c-10.868,5.153-21.644,10.708-32.503,16.402
	l-41.997-63.204C233.699,96.747,240.425,90.83,247.246,84.88z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M226.938,102.451l41.997,63.204c-9.728,5.079-19.515,10.257-29.502,15.325l-16.959-32.434l8.587-5.016
	l-16.458-31.051C218.737,109.247,222.84,105.886,226.938,102.451z"
      />
      <path
        className="piano-key"
        d="M13.147,116.843l12.689,7.005c-2.488-1.274-4.932-2.576-7.321-3.915C16.694,118.921,14.914,117.892,13.147,116.843z"
      />
      <path
        className="piano-key"
        d="M740.956,109.118c-6.066-1.69-12.146-3.465-18.229-5.323l29.347,8.339c-3.709-0.968-7.415-1.977-11.119-3.006
	L740.956,109.118z"
      />
      <path
        className="piano-key"
        d="M785.79,119.996c-4.209-0.842-8.423-1.746-12.654-2.704l27.554,5.446C795.739,121.919,790.767,120.994,785.79,119.996z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M732.396,177.016l19.821-64.838c6.979,1.837,13.938,3.547,20.878,5.101l-20.727,65.878l12.875,3.826
	l-14.599,47.572c-18.211-1.055-35.801-3.982-52.896-8.245l20.001-54.251L732.396,177.016z"
      />
      <polygon
        className="piano-key"
        points="282.215,56.334 274.056,62.343 299.538,99.154 308.071,94.55 320.218,88.003 293.373,48.125 "
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M406.358,14.038l-0.685,59.754l13.12,1.067l-3.867,47.56c-4.634-0.221-9.291-0.287-13.974-0.168
	c-5.214,0.128-10.331,0.472-15.371,1.019l0-0.01L371.851,16.175c6.175-0.944,12.587-1.599,19.242-1.911
	C396.139,14.029,401.224,13.943,406.358,14.038z"
      />
      <path
        className="piano-key"
        d="M999.092,96.781l8.342-3.027C1004.679,94.759,1001.899,95.771,999.092,96.781z"
      />
      <path
        className="piano-key"
        d="M887.086,126.005l18.095-2.536c-2.575,0.436-5.179,0.849-7.788,1.219c-3.413,0.493-6.844,0.942-10.308,1.327
	L887.086,126.005z"
      />
      <path
        className="piano-key"
        d="M752.369,183.156l12.875,3.826l17.609,5.241l17.836-69.486c-4.95-0.819-9.923-1.744-14.899-2.742
	c-4.209-0.842-8.423-1.746-12.654-2.704l-0.04-0.014L752.369,183.156z"
      />
      <path
        className="piano-key"
        d="M108.959,148.174l11.366,0.758c-2.4-0.049-4.835-0.185-7.316-0.386C111.654,148.435,110.301,148.314,108.959,148.174z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M35.786,164.424l13.079-30.305c4.946,1.891,10.049,3.638,15.3,5.23l-22.09,78.278
	c-10.5-3.601-20.6-9.043-30.221-15.758l17.355-39.76L35.786,164.424z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M597.137,129.594l29.132-58.899c6.246,2.277,12.505,4.545,18.762,6.812l-28.347,59.233l7.812,3.133
	l-24.573,49.175c-15.702-7.459-31.167-15.158-46.518-22.568l0-0.01l25.373-45.571L597.137,129.594z"
      />
      <path
        className="piano-key"
        d="M407.907,14.06c-0.135-0.001-0.249-0.01-0.374-0.009c-0.395-0.012-0.789-0.022-1.175-0.012l-0.685,59.754l13.12,1.067
	l13.761,1.119l8.98-59.247c0,0-8.882-0.837-17.887-1.58C417.548,14.646,411.383,14.186,407.907,14.06z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M133.392,148.6l0.75,34.936c0,0,4.679-0.973,9.064-1.906l2.266,35.235
	c-12.228,2.681-25.01,4.618-38.473,5.594l3.197-39.329l10.029,0.814l0.099-35.012C124.801,149.056,129.16,148.942,133.392,148.6z"
      />
      <path
        className="piano-key"
        d="M0,147.984l6.374,2.767l6.694,2.907l12.898-29.736l-0.129-0.073c-2.488-1.274-4.932-2.576-7.321-3.915
	c-1.822-1.012-3.602-2.042-5.369-3.091l-0.04-0.024L0,147.984z"
      />
      <path
        className="piano-key"
        d="M133.392,148.6l14.752-2.11c-2.34,0.497-4.706,0.918-7.116,1.253C138.521,148.111,135.98,148.394,133.392,148.6z"
      />
      <path
        className="piano-key"
        d="M1058.477,76.413l-6.706,2.088l-10.581,3.292l-0.182,0.058l3.669,43.208l9.535-2.087l6.707-1.463l-2.187-45.169
	C1058.648,76.354,1058.562,76.379,1058.477,76.413z"
      />
      <path
        className="piano-key"
        d="M980.512,103.336c-3.115,1.068-6.272,2.133-9.447,3.176c-4.129,1.371-8.307,2.707-12.533,3.997l-0.171,0.059l5.944,42.987
	l9.147-2.295l12.812-3.205l-5.474-44.812L980.512,103.336z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M180.095,135.1l9.108,28.268l6.633-2.343l15.059,33.673c-11.826,5.284-24.035,10.171-36.811,14.336
	l-8.794-67.457C170.363,139.72,175.296,137.54,180.095,135.1z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M1041.008,81.852l3.669,43.208l9.535-2.087l1.4,25.404c-13.755,2.004-27.313,5.949-41.033,11.109
	l-1.865-21.611l10.689-2.951l-4.934-45.173C1026.188,86.964,1033.701,84.286,1041.008,81.852z"
      />
      <polygon
        className="piano-key"
        points="531.18,100.357 544.921,106.459 557.818,112.181 586.535,56.339 571.361,51.088 554.873,45.366 554.73,45.323 "
      />
      <path
        className="piano-key"
        d="M616.684,136.741l7.812,3.133l20.41,8.174l27.87-60.677c-6.244-2.183-12.495-4.398-18.736-6.623l0.001-0.01
	c-2.739-0.983-5.491-1.966-8.231-2.949l-0.778-0.282L616.684,136.741z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M83.72,144.863l-9.65,32.925l7.371,1.765l-8.627,43.259c-3.058-0.155-6.153-0.355-9.286-0.61
	c-7.309-0.594-14.466-2.174-21.452-4.574l22.09-78.278c7.709,2.333,15.749,4.32,24.124,5.895l-0.05,0.236L83.72,144.863z"
      />
      <path
        className="piano-key"
        d="M23.038,159.932l6.171,2.177l6.577,2.315l13.079-30.305c-2.139-0.819-4.247-1.656-6.331-2.523
	c-2.204-0.918-4.375-1.865-6.524-2.831L23.038,159.932z"
      />
      <path
        className="piano-key"
        d="M700.318,166.166l17.432,5.892l14.646,4.958l19.821-64.838l-0.144-0.043c-3.709-0.968-7.415-1.977-11.119-3.006l0.001-0.01
	c-6.066-1.69-12.146-3.465-18.229-5.323L700.318,166.166z"
      />
      <path
        className="piano-key"
        d="M113.009,148.545c-1.354-0.11-2.708-0.231-4.049-0.372l-2.464-0.158l-2.812,34.583l6.513,0.53l10.029,0.814l0.099-35.012
	C117.925,148.882,115.49,148.747,113.009,148.545z"
      />
      <polygon
        className="piano-key"
        points="569.749,116.617 578.777,120.899 597.137,129.594 626.269,70.695 619.656,68.294 610.024,64.795 603.362,62.38 
	600.541,61.359 "
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M-7.567,186.137l13.94-35.386l6.694,2.907l12.898-29.736c3.268,1.671,6.612,3.285,10.045,4.844
	l-12.973,31.167l6.171,2.177l-17.355,39.76C5.138,197.182-1.352,191.877-7.567,186.137z"
      />
      <path
        className="piano-key"
        d="M36.011,128.765l12.854,5.354c-2.139-0.819-4.247-1.656-6.331-2.523C40.331,130.678,38.159,129.731,36.011,128.765z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M958.36,110.569l5.944,42.987l9.147-2.295l1.358,25.525c-9.546,4.512-19.307,9.232-29.392,13.971
	l-7.503-74.377C944.859,114.571,951.668,112.616,958.36,110.569z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M441.534,16.731l0.001-0.011c8.577,1.135,17.241,2.609,25.986,4.361l-16.016,56.438l11.799,3.749
	l-12.363,46.067c-11.881-2.61-23.865-4.334-36.016-4.916l3.867-47.56l13.761,1.119L441.534,16.731z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M1093.93,69.565l0.006,0.052l-8.441,77.712c-10.143-0.825-20.065-0.383-29.882,1.046l-1.4-25.404
	l6.707-1.463l-2.187-45.169l0.001-0.01C1071.122,72.821,1082.854,70.351,1093.93,69.565z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M887.085,126.015l4.514,51.352l11.242-3.103l3.693,33.562c-13.813,5.527-28.339,10.633-43.788,14.902
	l1.251-36.771l13.434-0.729l-1.194-57.889l-7.211,0.121C875.116,127.165,881.142,126.667,887.085,126.015z"
      />
      <path
        className="piano-key"
        d="M1007.434,93.754l2.06-0.748c-0.204,0.077-0.408,0.154-0.622,0.23C1008.398,93.406,1007.916,93.585,1007.434,93.754z"
      />
      <path
        className="piano-key"
        d="M74.07,177.788l7.371,1.765l7.768,1.869l6.307-34.944c-0.144-0.022-0.288-0.044-0.432-0.066l-6.845-0.931l-4.519-0.617
	L74.07,177.788z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M656.462,213.398l45.301-116.209c6.997,2.275,13.987,4.488,20.965,6.606l-22.409,62.371l17.432,5.892
	l-20.001,54.251C683.682,222.812,669.938,218.407,656.462,213.398z"
      />
      <path
        className="piano-key"
        d="M180.095,135.1l10.321-5.729c-2.298,1.385-4.612,2.716-6.963,3.973l0,0.01C182.343,133.95,181.223,134.536,180.095,135.1z"
      />
      <polygon
        className="piano-key"
        points="253.267,79.688 248.243,84.014 247.246,84.88 269.455,123.548 279.152,117.052 286.702,111.994 258.942,74.789 "
      />
      <path
        className="piano-key"
        d="M905.181,123.469c-2.575,0.436-5.179,0.849-7.788,1.219c-3.413,0.493-6.844,0.942-10.308,1.327l4.514,51.352l11.242-3.103
	l9.337-2.571l-6.732-48.265L905.181,123.469z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M274.056,62.343l25.481,36.811l8.534-4.604l27.38,40.463c-11.551,4.087-22.832,8.946-34.013,14.24
	l-22.287-32.201l7.55-5.058l-27.76-37.205C263.884,70.58,268.907,66.41,274.056,62.343z"
      />
      <polygon
        className="piano-key"
        points="451.506,77.519 463.305,81.268 477.882,85.908 496.604,27.807 478.758,23.681 467.593,21.098 467.521,21.082 "
      />
      <path
        className="piano-key"
        d="M869.025,127.461l-0.062,0.005c-0.994,0.044-1.984,0.088-2.978,0.122c-4.739,0.188-9.521,0.246-14.345,0.177l-0.043-0.003
	l-0.646,58.894l13.045-0.698l13.434-0.729l-1.194-57.889L869.025,127.461z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M-27.445,165.538l26.26-57.894c4.562,3.159,9.328,6.232,14.292,9.175L0,147.984l6.374,2.767l-13.94,35.386
	c-6.991-6.428-13.638-13.39-19.89-20.59L-27.445,165.538z"
      />
      <path
        className="piano-key"
        d="M206.737,118.46c-1.239,0.908-2.467,1.797-3.713,2.664l11.579-8.644C212,114.537,209.381,116.531,206.737,118.46z"
      />
      <path
        className="piano-key"
        d="M1058.477,76.413l0.257-0.083l-0.001,0.01C1058.648,76.354,1058.562,76.379,1058.477,76.413z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M523.872,35.477l0.001-0.01c10.219,3.079,20.504,6.382,30.857,9.856l-23.551,55.034l13.741,6.102
	l-21.888,45.844c-12.807-5.683-25.599-10.917-38.455-15.366l0.001-0.01L523.872,35.477z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M203.024,121.124l13.461,30.922l5.988-3.499l16.959,32.434c-9.313,4.729-18.796,9.357-28.538,13.717
	l-15.059-33.673l7.652-2.719l-13.072-28.934C194.694,126.805,198.884,124.045,203.024,121.124z"
      />
      <path
        className="piano-key"
        d="M95.516,146.478c-0.144-0.022-0.288-0.044-0.432-0.066l0.433,0.056L95.516,146.478z"
      />
      <path
        className="piano-key"
        d="M654.04,80.738c-2.739-0.983-5.491-1.966-8.231-2.949l26.967,9.582c-6.244-2.183-12.495-4.398-18.736-6.623L654.04,80.738z"
      />
      <path
        className="piano-key"
        d="M1009.493,93.006c-0.204,0.077-0.408,0.154-0.622,0.23c-0.473,0.17-0.955,0.349-1.438,0.518
	c-2.755,1.004-5.534,2.017-8.342,3.027l-2.713,0.976l6.445,42.851l9.89-2.734l10.689-2.951l-4.934-45.173L1009.493,93.006z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M851.598,127.762l-0.646,58.894l13.045-0.698l-1.251,36.771c-17.397,4.82-35.967,8.565-56.001,10.632
	l21.257-106.956c1.992,0.193,3.966,0.364,5.951,0.526C839.898,127.414,845.788,127.685,851.598,127.762z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M165.29,141.576l8.794,67.457l-0.001,0.011c-9.216,3.019-18.735,5.657-28.611,7.82l-2.266-35.235
	c3.621-0.758,7.041-1.478,7.519-1.596c1.062-0.268-2.581-33.544-2.581-33.544C154.072,145.265,159.765,143.604,165.29,141.576z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M1093.93,69.565c8.437-0.594,16.491-0.21,24.174,1.435c4.645,0.992,9.103,2.093,13.377,3.284l-16.49,47.813
	l10.492,5.224l-11.424,25.808c-8.793-2.952-18.298-4.964-28.564-5.798l8.441-77.712L1093.93,69.565z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M478.758,23.681l-11.165-2.583c18.433,3.695,37.222,8.636,56.28,14.369l-0.001,0.01l-39.293,101.451
	l-0.001,0.01c-11.155-3.863-22.347-7.135-33.637-9.603l12.363-46.067l14.578,4.641l18.722-58.102L478.758,23.681z"
      />
      <path
        className="piano-key"
        d="M340.549,23.934c-0.872,0.356-10.686,4.325-19.375,8.281c-2.94,1.343-5.744,2.676-8.038,3.874
	c-0.033,0.019-0.065,0.036-0.098,0.044c-0.839,0.462-1.677,0.915-2.517,1.388l22.075,43.257l9.048-1.97l12.142-2.645l-13.173-52.254
	C340.614,23.908,340.592,23.917,340.549,23.934z"
      />
      <path
        className="piano-key"
        d="M206.737,118.46c-1.239,0.908-2.467,1.797-3.713,2.664l13.461,30.922l5.988-3.499l8.587-5.016l-16.458-31.051
	C212,114.537,209.381,116.531,206.737,118.46z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M996.379,97.757l6.445,42.851l9.89-2.734l1.865,21.611c-13.012,4.895-26.163,10.872-39.769,17.3
	l-1.358-25.525l12.812-3.205l-5.474-44.812C986.069,101.426,991.266,99.59,996.379,97.757z"
      />
      <path
        className="piano-key"
        d="M1131.531,74.297c0.979,0.247,2.058,0.553,3.225,0.918C1133.699,74.9,1132.621,74.594,1131.531,74.297z"
      />
      <path
        className="piano-key"
        d="M407.533,14.05c-0.395-0.012-0.789-0.022-1.175-0.012l0.001-0.011C406.692,14.023,407.086,14.034,407.533,14.05z"
      />
      <path
        className="piano-key"
        d="M183.453,133.344l0,0.01c-1.11,0.596-2.23,1.182-3.358,1.746l9.108,28.268l6.633-2.343l7.652-2.719l-13.072-28.934
	C188.118,130.756,185.804,132.087,183.453,133.344z"
      />
      <path
        className="piano-key"
        d="M2343.744,105.378c-0.287-0.199-0.571-0.41-0.856-0.611C2343.154,104.946,2343.439,105.146,2343.744,105.378z"
      />
      <path
        className="piano-key"
        d="M1471.846,42.452c-0.839,0.462-1.677,0.915-2.517,1.388C1470.061,43.41,1470.91,42.948,1471.846,42.452z"
      />
      <path
        className="piano-key"
        d="M2117.338,116.83l21.98-7.174c-3.115,1.068-6.272,2.134-9.447,3.177C2125.742,114.204,2121.564,115.54,2117.338,116.83z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M1452.18,54.445c5.528-3.744,11.229-7.298,17.149-10.605l22.075,43.257l9.048-1.971l21.192,48.224
	l-0.001,0.01c-9.35,2.092-18.458,4.806-27.386,7.974l-27.38-40.463l12.147-6.547L1452.18,54.445z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M1530.657,22.495l13.731,107.084v0.01c-7.769,0.815-15.336,2.104-22.745,3.771l0.001-0.01l-21.192-48.224
	l12.142-2.645l-13.174-52.254C1509.177,26.837,1519.551,24.184,1530.657,22.495z"
      />
      <path
        className="piano-key"
        d="M2010.447,134.085l17.322-0.299c-0.994,0.044-1.984,0.088-2.978,0.121C2020.053,134.095,2015.271,134.154,2010.447,134.085z
	"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M1716.625,118.5l28.717-55.842c4.662,1.659,9.334,3.329,14.006,5.021l-30.793,55.258l9.029,4.282
	l-25.373,45.572v0.01c-10.166-4.906-20.277-9.683-30.371-14.177l21.888-45.845L1716.625,118.5z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M1941.66,198.543l17.836-69.485c9.156,1.545,18.269,2.775,27.312,3.666l-21.257,106.956
	c-17.519,1.812-36.149,2.348-56.1,1.195l14.599-47.572L1941.66,198.543z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M1815.269,219.719c-19.315-7.18-38.093-15.586-56.54-24.35l24.573-49.175l20.41,8.174l27.87-60.677
	c9.664,3.377,19.336,6.661,28.987,9.818L1815.269,219.719z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2096.723,122.699l7.503,74.377c-12.4,5.828-25.3,11.65-38.885,17.07l-3.693-33.562l9.337-2.57
	l-6.732-48.266l-0.265,0.041C2075.194,127.9,2086.101,125.467,2096.723,122.699z"
      />
      <path
        className="piano-key"
        d="M1899.763,115.438c-6.066-1.69-12.146-3.465-18.229-5.323l29.347,8.34c-3.709-0.968-7.415-1.977-11.119-3.006
	L1899.763,115.438z"
      />
      <path
        className="piano-key"
        d="M1944.597,126.316c-4.209-0.842-8.423-1.746-12.654-2.704l27.554,5.446C1954.546,128.239,1949.573,127.314,1944.597,126.316
	z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M1891.203,183.336l19.821-64.838c6.979,1.838,13.938,3.547,20.878,5.101l-20.727,65.878l12.875,3.826
	l-14.599,47.572c-18.211-1.055-35.801-3.982-52.896-8.246l20.001-54.25L1891.203,183.336z"
      />
      <polygon
        className="piano-key"
        points="1441.021,62.654 1432.863,68.663 1458.344,105.474 1466.878,100.871 1479.025,94.324 1452.18,54.445 "
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M1565.164,20.358l-0.685,59.755l13.12,1.066l-3.867,47.56c-4.634-0.221-9.291-0.287-13.974-0.169
	c-5.214,0.129-10.331,0.473-15.37,1.02v-0.01l-13.731-107.084c6.175-0.944,12.587-1.599,19.242-1.911
	C1554.945,20.349,1560.031,20.263,1565.164,20.358z"
      />
      <path
        className="piano-key"
        d="M2157.898,103.101l8.342-3.027C2163.485,101.079,2160.706,102.091,2157.898,103.101z"
      />
      <path
        className="piano-key"
        d="M2045.893,132.325l18.095-2.536c-2.575,0.437-5.179,0.85-7.788,1.22c-3.413,0.493-6.844,0.942-10.308,1.327
	L2045.893,132.325z"
      />
      <path
        className="piano-key"
        d="M1911.176,189.477l12.875,3.826l17.609,5.24l17.836-69.485c-4.95-0.819-9.923-1.744-14.899-2.742
	c-4.209-0.842-8.423-1.746-12.654-2.704l-0.04-0.014L1911.176,189.477z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M1755.943,135.914l29.132-58.898c6.246,2.276,12.505,4.545,18.762,6.812l-28.347,59.232l7.812,3.134
	l-24.573,49.175c-15.702-7.459-31.166-15.158-46.518-22.568v-0.01l25.373-45.572L1755.943,135.914z"
      />
      <path
        className="piano-key"
        d="M1566.713,20.379c-0.135-0.001-0.248-0.01-0.373-0.009c-0.395-0.012-0.789-0.022-1.176-0.012l-0.685,59.755l13.12,1.066
	l13.762,1.119l8.979-59.247c0,0-8.882-0.837-17.888-1.58C1576.354,20.965,1570.189,20.506,1566.713,20.379z"
      />
      <path
        className="piano-key"
        d="M2217.283,82.733l-6.706,2.089l-10.581,3.291l-0.182,0.059l3.669,43.207l9.535-2.086l6.707-1.463l-2.187-45.17
	C2217.455,82.674,2217.369,82.699,2217.283,82.733z"
      />
      <path
        className="piano-key"
        d="M2139.318,109.656c-3.115,1.068-6.272,2.134-9.447,3.177c-4.129,1.371-8.307,2.707-12.533,3.997l-0.171,0.059l5.944,42.987
	l9.147-2.296l12.812-3.204l-5.474-44.812L2139.318,109.656z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2199.814,88.171l3.669,43.207l9.535-2.086l1.4,25.403c-13.755,2.004-27.313,5.949-41.033,11.109
	l-1.865-21.611l10.689-2.95l-4.934-45.174C2184.995,93.284,2192.508,90.606,2199.814,88.171z"
      />
      <polygon
        className="piano-key"
        points="1689.986,106.677 1703.728,112.779 1716.625,118.5 1745.342,62.659 1730.168,57.408 1713.68,51.685 
	1713.537,51.643 "
      />
      <path
        className="piano-key"
        d="M1775.49,143.06l7.812,3.134l20.41,8.174l27.87-60.677c-6.244-2.184-12.495-4.398-18.736-6.623l0.001-0.01
	c-2.739-0.983-5.491-1.967-8.231-2.949l-0.778-0.281L1775.49,143.06z"
      />
      <path
        className="piano-key"
        d="M1859.125,172.486l17.432,5.893l14.646,4.957l19.821-64.838l-0.144-0.043c-3.709-0.968-7.415-1.977-11.119-3.006
	l0.001-0.011c-6.066-1.69-12.146-3.465-18.229-5.323L1859.125,172.486z"
      />
      <polygon
        className="piano-key"
        points="1728.555,122.937 1737.584,127.219 1755.943,135.914 1785.075,77.015 1778.463,74.614 1768.83,71.115 
	1762.168,68.7 1759.348,67.679 "
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M2117.167,116.888l5.944,42.987l9.147-2.296l1.358,25.526c-9.546,4.512-19.307,9.232-29.392,13.971
	l-7.503-74.377C2103.666,120.89,2110.475,118.936,2117.167,116.888z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M1600.341,23.051l0.001-0.011c8.576,1.135,17.24,2.609,25.986,4.361l-16.016,56.438l11.799,3.748
	l-12.363,46.067c-11.881-2.61-23.865-4.334-36.016-4.916l3.867-47.56l13.762,1.119L1600.341,23.051z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2252.736,75.884l0.006,0.053l-8.441,77.713c-10.143-0.825-20.065-0.383-29.882,1.046l-1.4-25.403
	l6.707-1.463l-2.187-45.17l0.001-0.01C2229.929,79.14,2241.661,76.67,2252.736,75.884z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2045.892,132.335l4.514,51.352l11.242-3.104l3.693,33.562c-13.813,5.527-28.339,10.633-43.788,14.902
	l1.251-36.771l13.434-0.729l-1.194-57.889l-7.211,0.121C2033.923,133.485,2039.948,132.986,2045.892,132.335z"
      />
      <path
        className="piano-key"
        d="M2166.24,100.074l2.06-0.748c-0.204,0.077-0.408,0.154-0.622,0.23C2167.205,99.726,2166.723,99.906,2166.24,100.074z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M1815.269,219.719l45.301-116.209c6.997,2.275,13.987,4.488,20.965,6.605l-22.409,62.372l17.432,5.893
	l-20.001,54.25C1842.488,229.133,1828.745,224.727,1815.269,219.719z"
      />
      <path
        className="piano-key"
        d="M2063.987,129.789c-2.575,0.437-5.179,0.85-7.788,1.22c-3.413,0.493-6.844,0.942-10.308,1.327l4.514,51.352l11.242-3.104
	l9.337-2.57l-6.732-48.266L2063.987,129.789z"
      />
      <polygon
        className="piano-key"
        points="1610.312,83.839 1622.111,87.587 1636.689,92.228 1655.411,34.126 1637.564,30.001 1626.399,27.417 
	1626.328,27.401 "
      />
      <path
        className="piano-key"
        d="M2027.832,133.781l-0.062,0.006c-0.994,0.044-1.984,0.088-2.978,0.121c-4.739,0.188-9.521,0.246-14.345,0.178l-0.043-0.004
	l-0.646,58.893l13.045-0.697l13.434-0.729l-1.194-57.889L2027.832,133.781z"
      />
      <path
        className="piano-key"
        d="M2217.283,82.733l0.257-0.083l-0.001,0.01C2217.455,82.674,2217.369,82.699,2217.283,82.733z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M1682.679,41.797l0.001-0.01c10.219,3.079,20.504,6.382,30.857,9.856l-23.551,55.034l13.741,6.102
	l-21.888,45.845c-12.808-5.683-25.6-10.917-38.455-15.366l0.001-0.01L1682.679,41.797z"
      />
      <path
        className="piano-key"
        d="M1254.322,152.797c-0.144-0.022-0.287-0.044-0.432-0.066l0.433,0.056L1254.322,152.797z"
      />
      <path
        className="piano-key"
        d="M1812.847,87.058c-2.739-0.983-5.491-1.967-8.231-2.949l26.967,9.582c-6.244-2.184-12.495-4.398-18.736-6.623
	L1812.847,87.058z"
      />
      <path
        className="piano-key"
        d="M2168.3,99.326c-0.204,0.077-0.408,0.154-0.622,0.23c-0.473,0.17-0.955,0.35-1.438,0.518
	c-2.755,1.005-5.534,2.018-8.342,3.027l-2.713,0.977l6.445,42.851l9.89-2.734l10.689-2.95l-4.934-45.174L2168.3,99.326z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2010.404,134.082l-0.646,58.893l13.045-0.697l-1.251,36.771c-17.397,4.82-35.967,8.564-56.001,10.631
	l21.257-106.956c1.992,0.193,3.966,0.365,5.951,0.526C1998.705,133.734,2004.595,134.004,2010.404,134.082z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2252.736,75.884c8.437-0.594,16.491-0.209,24.174,1.436c4.645,0.991,9.103,2.093,13.377,3.283
	l-16.49,47.812l10.492,5.225l-11.424,25.808c-8.793-2.952-18.298-4.964-28.564-5.798l8.441-77.713L2252.736,75.884z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M1637.564,30.001l-11.165-2.583c18.433,3.695,37.222,8.636,56.28,14.369l-0.001,0.01l-39.293,101.451
	l-0.001,0.01c-11.154-3.862-22.348-7.135-33.637-9.603l12.363-46.067l14.578,4.641l18.722-58.102L1637.564,30.001z"
      />
      <path
        className="piano-key"
        d="M1499.355,30.253c-0.872,0.356-10.686,4.325-19.375,8.281c-2.939,1.343-5.744,2.676-8.037,3.874
	c-0.033,0.019-0.065,0.036-0.098,0.044c-0.839,0.462-1.677,0.915-2.517,1.388l22.075,43.257l9.048-1.971l12.142-2.645
	l-13.174-52.254C1499.42,30.228,1499.398,30.237,1499.355,30.253z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M2155.186,104.078l6.445,42.851l9.89-2.734l1.865,21.611c-13.012,4.896-26.163,10.873-39.769,17.3
	l-1.358-25.526l12.812-3.204l-5.474-44.812C2144.876,107.746,2150.072,105.91,2155.186,104.078z"
      />
      <path
        className="piano-key"
        d="M2290.338,80.617c0.979,0.247,2.058,0.553,3.225,0.918C2292.506,81.22,2291.428,80.914,2290.338,80.617z"
      />
      <path
        className="piano-key"
        d="M1566.34,20.37c-0.395-0.012-0.789-0.022-1.176-0.012l0.002-0.011C1565.499,20.343,1565.893,20.354,1566.34,20.37z"
      />
      <path
        className="piano-key"
        d="M1131.531,74.297c-0.021-0.001-0.03-0.013-0.051-0.014l-16.49,47.813l10.492,5.224l14.514,7.216l24.643-43.756
	C1155.326,84.465,1144.533,78.773,1131.531,74.297z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M1180.85,103.49c-4.923-4.39-10.265-8.676-16.211-12.709l-24.643,43.756l-14.514-7.216l-11.424,25.808
	c16.505,5.525,30.525,14.335,42.381,24.812l13.881-24.046l-13.892-12.307L1180.85,103.49z"
      />
      <path
        className="piano-key"
        d="M1180.85,103.49l-24.421,38.097l13.892,12.307l11.351,10.05l25.082-34.242
	C1198.793,121.364,1190.647,112.227,1180.85,103.49z"
      />
      <path
        className="piano-key"
        d="M1241.108,155.894l-8.231,28.213l7.371,1.766l7.768,1.869l5.126-28.401C1248.836,158.564,1244.85,157.395,1241.108,155.894z
	"
      />
      <path
        className="piano-key"
        d="M1264.807,160.425l-2.316,28.493l6.514,0.531l10.029,0.814l0.087-30.777
	C1273.993,160.198,1269.248,160.488,1264.807,160.425z"
      />
      <path
        className="piano-key"
        d="M1292.252,157.374l0.697,32.481c0,0,4.68-0.973,9.064-1.906c3.62-0.758,7.04-1.479,7.518-1.596
	c0.934-0.235-1.768-25.972-2.435-32.194C1302.236,155.379,1297.296,156.442,1292.252,157.374z"
      />
      <path
        className="piano-key"
        d="M1339.423,143.039l8.587,26.648l6.633-2.344l7.652-2.719l-12.122-26.831
	C1346.595,139.681,1343.016,141.427,1339.423,143.039z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M1375.292,158.366l-12.199-28.023c-4.318,2.706-8.618,5.184-12.92,7.452l12.122,26.831l-7.652,2.719
	l15.059,33.674c9.742-4.359,19.225-8.988,28.538-13.717l-16.96-32.434L1375.292,158.366z"
      />
      <path
        className="piano-key"
        d="M1363.093,130.342l12.199,28.023l5.987-3.499l8.588-5.016l-14.666-27.669
	C1371.139,125.112,1367.107,127.827,1363.093,130.342z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M1375.201,122.182l14.666,27.669l-8.588,5.016l16.96,32.434c9.987-5.068,19.774-10.246,29.503-15.326
	l-39.674-59.707C1383.731,115.823,1379.449,119.119,1375.201,122.182z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M1437.959,123.372l-9.697,6.496l-20.299-35.342c-6.794,6.53-13.405,12.424-19.895,17.743l39.674,59.707
	c10.859-5.694,21.635-11.249,32.503-16.402L1437.959,123.372z"
      />
      <path
        className="piano-key"
        d="M1419.312,83.203c-3.846,3.974-7.624,7.743-11.349,11.322l20.299,35.342l9.697-6.496l7.55-5.058L1419.312,83.203z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M1466.878,100.871l-8.534,4.604l-25.48-36.812c-4.615,5.128-9.127,9.966-13.552,14.541l26.197,35.111
	l-7.55,5.058l22.286,32.201c11.181-5.294,22.462-10.153,34.013-14.239L1466.878,100.871z"
      />
      <path
        className="piano-key"
        fill="#E19FC7"
        d="M1224.625,146.318c-6.251-4.784-12.014-10.481-17.872-16.616l-25.082,34.242l-11.351-10.05l-13.881,24.046
	c0,0,13.348,11.732,34.036,24.042L1224.625,146.318z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M1216.233,215.354c5.502,2.434,11.188,4.336,16.979,5.8l7.036-35.28l-7.371-1.766l8.266-28.199
	c-6.041-2.419-11.44-5.704-16.518-9.59l-34.149,55.664C1198.154,206.55,1206.836,211.196,1216.233,215.354z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M1266.104,225.117l2.899-35.668l-6.514-0.531l2.316-28.493c-4.145-0.059-8.023-0.426-11.665-1.083
	l-5.126,28.401l-7.768-1.869l-7.036,35.28C1243.952,223.867,1255.064,225.04,1266.104,225.117z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M1304.176,221.571l-2.162-33.622c-4.385,0.934-9.064,1.906-9.064,1.906l-0.697-32.481
	c-4.293,0.792-8.66,1.492-13.12,2.111c-0.004,0-0.008,0.001-0.012,0.001l-0.087,30.777l-10.029-0.814l-2.899,35.668
	C1279.152,225.209,1292.09,223.779,1304.176,221.571z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M1302.014,187.949l2.162,33.622c10.321-1.886,20.02-4.333,28.628-6.882l-8.557-65.632
	c-5.632,1.956-11.334,3.643-17.15,5.102c0.667,6.222,3.368,31.958,2.435,32.194C1309.054,186.471,1305.634,187.191,1302.014,187.949
	z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M1360.483,205.014c3.1-1.303,6.172-2.636,9.218-3.996l-15.059-33.674l-6.633,2.344l-8.587-26.648
	c-5.021,2.253-10.07,4.245-15.176,6.019l8.557,65.632C1344.379,211.262,1353.979,207.661,1360.483,205.014z"
      />
      <path
        className="piano-key"
        d="M3277.391,123.363l21.98-7.174c-3.115,1.068-6.272,2.133-9.447,3.176C3285.795,120.736,3281.617,122.072,3277.391,123.363z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M2612.232,60.979c5.528-3.744,11.229-7.299,17.149-10.605l22.075,43.258l9.048-1.971l21.192,48.223
	l-0.001,0.01c-9.35,2.092-18.458,4.807-27.386,7.975l-27.38-40.463l12.147-6.547L2612.232,60.979z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M2690.71,29.028l13.731,107.085v0.01c-7.769,0.814-15.336,2.104-22.745,3.77l0.001-0.01l-21.192-48.223
	l12.142-2.645l-13.174-52.255C2669.229,33.37,2679.604,30.717,2690.71,29.028z"
      />
      <path
        className="piano-key"
        d="M3170.5,140.619l17.322-0.299c-0.994,0.043-1.984,0.088-2.978,0.121C3180.105,140.629,3175.323,140.688,3170.5,140.619z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M2876.678,125.033l28.717-55.842c4.662,1.66,9.334,3.33,14.006,5.021l-30.793,55.258l9.029,4.281
	l-25.373,45.572v0.01c-10.166-4.906-20.277-9.684-30.371-14.178l21.888-45.844L2876.678,125.033z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M3101.713,205.076l17.836-69.484c9.156,1.545,18.269,2.775,27.312,3.666l-21.257,106.955
	c-17.519,1.812-36.149,2.348-56.1,1.195l14.599-47.572L3101.713,205.076z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2975.321,226.252c-19.315-7.18-38.093-15.586-56.54-24.35l24.573-49.176l20.41,8.174l27.87-60.676
	c9.664,3.377,19.336,6.66,28.987,9.818L2975.321,226.252z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M3256.775,129.232l7.503,74.377c-12.4,5.828-25.3,11.65-38.885,17.07l-3.693-33.562l9.337-2.57
	l-6.732-48.266l-0.265,0.041C3235.247,134.434,3246.153,132,3256.775,129.232z"
      />
      <path
        className="piano-key"
        d="M3059.815,121.971c-6.066-1.689-12.146-3.465-18.229-5.322l29.347,8.34c-3.709-0.969-7.415-1.977-11.119-3.006
	L3059.815,121.971z"
      />
      <path
        className="piano-key"
        d="M3104.649,132.85c-4.209-0.842-8.423-1.746-12.654-2.705l27.554,5.447C3114.599,134.771,3109.626,133.848,3104.649,132.85z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M3051.256,189.869l19.821-64.838c6.979,1.838,13.938,3.547,20.878,5.1l-20.727,65.879l12.875,3.826
	l-14.599,47.572c-18.211-1.055-35.801-3.982-52.896-8.246l20.001-54.25L3051.256,189.869z"
      />
      <polygon
        className="piano-key"
        points="2601.074,70.188 2592.916,76.195 2618.396,113.008 2626.931,108.404 2639.078,101.857 2612.232,61.979 "
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2725.217,26.891l-0.685,59.756l13.12,1.066l-3.867,47.559c-4.634-0.221-9.291-0.287-13.974-0.168
	c-5.214,0.129-10.331,0.473-15.37,1.02v-0.01L2690.71,29.028c6.175-0.944,12.587-1.599,19.242-1.911
	C2714.998,26.882,2720.084,26.796,2725.217,26.891z"
      />
      <path
        className="piano-key"
        d="M3317.951,110.635l8.342-3.027C3323.538,108.611,3320.759,109.625,3317.951,110.635z"
      />
      <path
        className="piano-key"
        d="M3205.945,138.857l18.095-2.535c-2.575,0.436-5.179,0.85-7.788,1.219c-3.413,0.494-6.844,0.943-10.308,1.328
	L3205.945,138.857z"
      />
      <path
        className="piano-key"
        d="M3071.229,196.01l12.875,3.826l17.609,5.24l17.836-69.484c-4.95-0.82-9.923-1.744-14.899-2.742
	c-4.209-0.842-8.423-1.746-12.654-2.705l-0.04-0.014L3071.229,196.01z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2915.996,142.447l29.132-58.898c6.246,2.275,12.505,4.545,18.762,6.812l-28.347,59.232l7.812,3.133
	l-24.573,49.176c-15.702-7.459-31.166-15.158-46.518-22.568v-0.01l25.373-45.572L2915.996,142.447z"
      />
      <path
        className="piano-key"
        d="M2726.766,27.912c-0.135,0-0.248-0.01-0.373-0.009c-0.395-0.012-0.789-0.022-1.176-0.013l-0.685,59.756l13.12,1.066
	l13.762,1.119l8.979-59.248c0,0-8.882-0.836-17.888-1.579C2736.407,28.498,2730.242,28.039,2726.766,27.912z"
      />
      <path
        className="piano-key"
        d="M3299.371,116.189c-3.115,1.068-6.272,2.133-9.447,3.176c-4.129,1.371-8.307,2.707-12.533,3.998l-0.171,0.059l5.944,42.986
	l9.147-2.295l12.812-3.205l-5.474-44.811L3299.371,116.189z"
      />
      <polygon
        className="piano-key"
        points="2850.039,113.211 2863.78,119.312 2876.678,125.033 2905.395,69.191 2890.221,63.941 2873.732,58.219 
	2873.59,58.176 "
      />
      <path
        className="piano-key"
        d="M2935.543,149.594l7.812,3.133l20.41,8.174l27.87-60.676c-6.244-2.184-12.495-4.398-18.736-6.623l0.001-0.01
	c-2.739-0.984-5.491-1.967-8.231-2.949l-0.778-0.281L2935.543,149.594z"
      />
      <path
        className="piano-key"
        d="M3019.178,179.02l17.432,5.893l14.646,4.957l19.821-64.838l-0.144-0.043c-3.709-0.969-7.415-1.977-11.119-3.006l0.001-0.012
	c-6.066-1.689-12.146-3.465-18.229-5.322L3019.178,179.02z"
      />
      <polygon
        className="piano-key"
        points="2888.607,129.471 2897.637,133.752 2915.996,142.447 2945.128,83.549 2938.516,81.146 2928.883,77.648 
	2922.221,75.232 2919.4,74.213 "
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M3277.22,123.422l5.944,42.986l9.147-2.295l1.358,25.525c-9.546,4.512-19.307,9.232-29.392,13.971
	l-7.503-74.377C3263.719,127.424,3270.527,125.469,3277.22,123.422z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2760.394,29.584l0.001-0.01c8.576,1.135,17.24,2.609,25.986,4.36l-16.016,56.438l11.799,3.748
	l-12.363,46.066c-11.881-2.609-23.865-4.334-36.016-4.916l3.867-47.559l13.762,1.119L2760.394,29.584z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M3205.944,138.869l4.514,51.352l11.242-3.104l3.693,33.562c-13.813,5.527-28.339,10.633-43.788,14.902
	l1.251-36.771l13.434-0.729l-1.194-57.889l-7.211,0.121C3193.976,140.018,3200.001,139.52,3205.944,138.869z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2975.321,226.252l45.301-116.209c6.997,2.275,13.987,4.488,20.965,6.605l-22.409,62.371l17.432,5.893
	l-20.001,54.25C3002.541,235.666,2988.798,231.26,2975.321,226.252z"
      />
      <path
        className="piano-key"
        d="M3224.04,136.322c-2.575,0.436-5.179,0.85-7.788,1.219c-3.413,0.494-6.844,0.943-10.308,1.328l4.514,51.352l11.242-3.104
	l9.337-2.57l-6.732-48.266L3224.04,136.322z"
      />
      <polygon
        className="piano-key"
        points="2770.365,91.373 2782.164,95.121 2796.742,99.762 2815.464,41.66 2797.617,37.534 2786.452,34.951 2786.381,34.935 
	"
      />
      <path
        className="piano-key"
        d="M3187.885,140.314l-0.062,0.006c-0.994,0.043-1.984,0.088-2.978,0.121c-4.739,0.188-9.521,0.246-14.345,0.178l-0.043-0.004
	l-0.646,58.893l13.045-0.697l13.434-0.729l-1.194-57.889L3187.885,140.314z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M2842.731,48.33l0.001-0.01c10.219,3.078,20.504,6.381,30.857,9.855l-23.551,55.035l13.741,6.102
	l-21.888,45.844c-12.808-5.682-25.6-10.916-38.455-15.365l0.001-0.01L2842.731,48.33z"
      />
      <path
        className="piano-key"
        d="M2414.375,159.33c-0.144-0.021-0.287-0.043-0.432-0.066l0.433,0.057L2414.375,159.33z"
      />
      <path
        className="piano-key"
        d="M2972.899,94.592c-2.739-0.984-5.491-1.967-8.231-2.949l26.967,9.582c-6.244-2.184-12.495-4.398-18.736-6.623
	L2972.899,94.592z"
      />
      <path
        className="piano-key"
        d="M3328.353,105.859c-0.204,0.076-0.408,0.154-0.622,0.23c-0.473,0.17-0.955,0.35-1.438,0.518
	c-2.755,1.004-5.534,2.018-8.342,3.027l-2.713,0.977l6.445,42.85l9.89-2.734l10.689-2.949l-4.934-45.174L3328.353,105.859z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M3170.457,140.615l-0.646,58.893l13.045-0.697l-1.251,36.771c-17.397,4.82-35.967,8.564-56.001,10.631
	l21.257-106.955c1.992,0.193,3.966,0.365,5.951,0.525C3158.758,140.268,3164.647,140.537,3170.457,140.615z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2797.617,36.534l-11.165-2.583c18.433,3.694,37.222,8.635,56.28,14.369l-0.001,0.01l-39.293,101.451
	l-0.001,0.01c-11.154-3.863-22.348-7.135-33.637-9.604l12.363-46.066l14.578,4.641l18.722-58.102L2797.617,36.534z"
      />
      <path
        className="piano-key"
        d="M2659.408,37.787c-0.872,0.355-10.686,4.324-19.375,8.28c-2.939,1.343-5.744,2.677-8.037,3.874
	c-0.033,0.018-0.065,0.035-0.098,0.044c-0.839,0.462-1.677,0.915-2.517,1.388l22.075,43.258l9.048-1.971l12.142-2.645
	l-13.174-52.255C2659.473,37.761,2659.451,37.77,2659.408,37.787z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M3315.238,110.611l6.445,42.85l9.89-2.734l1.865,21.611c-13.012,4.896-26.163,10.873-39.769,17.301
	l-1.358-25.525l12.812-3.205l-5.474-44.811C3304.929,114.279,3310.125,112.443,3315.238,110.611z"
      />
      <path
        className="piano-key"
        d="M2401.161,162.428l-8.231,28.213l7.371,1.766l7.768,1.869l5.126-28.4C2408.889,165.098,2404.902,163.928,2401.161,162.428z"
      />
      <path
        className="piano-key"
        d="M2424.859,166.958l-2.316,28.493l6.514,0.531l10.029,0.814l0.087-30.777
	C2434.046,166.731,2429.301,167.021,2424.859,166.958z"
      />
      <path
        className="piano-key"
        d="M2452.305,163.907l0.697,32.481c0,0,4.68-0.973,9.064-1.906c3.62-0.758,7.04-1.479,7.518-1.596
	c0.934-0.235-1.768-25.973-2.435-32.193C2462.289,161.912,2457.349,162.976,2452.305,163.907z"
      />
      <path
        className="piano-key"
        d="M2499.476,149.572l8.587,26.648l6.633-2.344l7.652-2.719l-12.122-26.831
	C2506.647,146.214,2503.068,147.961,2499.476,149.572z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M2535.345,164.898l-12.199-28.023c-4.318,2.707-8.618,5.184-12.92,7.452l12.122,26.831l-7.652,2.719
	l15.059,33.674c9.742-4.359,19.225-8.988,28.538-13.717l-16.96-32.434L2535.345,164.898z"
      />
      <path
        className="piano-key"
        d="M2523.146,136.875l12.199,28.023l5.987-3.498l8.588-5.016l-14.666-27.67C2531.191,131.645,2527.16,134.359,2523.146,136.875
	z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2535.254,128.715l14.666,27.67l-8.588,5.016l16.96,32.434c9.987-5.068,19.774-10.246,29.503-15.326
	l-39.674-59.707C2543.784,122.355,2539.502,125.652,2535.254,128.715z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2598.012,129.904l-9.697,6.496l-20.299-35.342c-6.794,6.529-13.405,12.424-19.895,17.742l39.674,59.707
	c10.859-5.693,21.635-11.248,32.503-16.402L2598.012,129.904z"
      />
      <path
        className="piano-key"
        d="M2579.364,89.736c-3.846,3.974-7.624,7.743-11.349,11.322l20.299,35.342l9.697-6.496l7.55-5.057L2579.364,89.736z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2626.931,107.404l-8.534,4.604l-25.48-36.812c-4.615,5.129-9.127,9.967-13.552,14.541l26.197,35.111
	l-7.55,5.057l22.286,32.201c11.181-5.293,22.462-10.152,34.013-14.238L2626.931,107.404z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2376.286,221.887c5.502,2.434,11.188,4.336,16.979,5.8l7.036-35.28l-7.371-1.766l8.266-28.199
	c-6.041-2.419-11.44-5.704-16.518-9.59l-34.149,55.663C2358.207,213.083,2366.889,217.729,2376.286,221.887z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2426.157,231.65l2.899-35.668l-6.514-0.531l2.316-28.493c-4.145-0.06-8.023-0.427-11.665-1.083l-5.126,28.4
	l-7.768-1.869l-7.036,35.28C2404.005,230.4,2415.117,231.573,2426.157,231.65z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2464.229,228.104l-2.162-33.622c-4.385,0.934-9.064,1.906-9.064,1.906l-0.697-32.481
	c-4.293,0.792-8.66,1.491-13.12,2.111c-0.004,0.001-0.008,0.001-0.012,0.001l-0.087,30.777l-10.029-0.814l-2.899,35.668
	C2439.205,231.742,2452.143,230.312,2464.229,228.104z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2462.066,194.482l2.162,33.622c10.321-1.886,20.02-4.333,28.628-6.882l-8.557-65.632
	c-5.632,1.956-11.334,3.643-17.15,5.103c0.667,6.221,3.368,31.958,2.435,32.193C2469.106,193.004,2465.687,193.725,2462.066,194.482
	z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M2520.536,211.547c3.1-1.303,6.172-2.636,9.218-3.996l-15.059-33.674l-6.633,2.344l-8.587-26.648
	c-5.021,2.253-10.07,4.245-15.176,6.019l8.557,65.632C2504.432,217.795,2514.031,214.194,2520.536,211.547z"
      />
      <path
        className="piano-key"
        d="M2159.088,103.057l8.342-3.027C2164.675,101.034,2161.896,102.047,2159.088,103.057z"
      />
      <path
        className="piano-key"
        d="M2218.473,82.688l-6.706,2.089l-10.581,3.291l-0.182,0.059l3.669,43.207l9.535-2.086l6.707-1.463l-2.187-45.17
	C2218.645,82.63,2218.559,82.654,2218.473,82.688z"
      />
      <path
        className="piano-key"
        fill="#FFE55B"
        d="M2201.004,88.127l3.669,43.207l9.535-2.086l1.4,25.403c-13.755,2.004-27.313,5.949-41.033,11.109
	l-1.865-21.611l10.689-2.95l-4.934-45.174C2186.185,93.239,2193.697,90.562,2201.004,88.127z"
      />
      <path
        className="piano-key"
        fill="#4CC4F2"
        d="M2253.926,75.84l0.006,0.053l-8.441,77.713c-10.143-0.825-20.065-0.383-29.882,1.046l-1.4-25.403
	l6.707-1.463l-2.187-45.17l0.001-0.01C2231.118,79.096,2242.851,76.626,2253.926,75.84z"
      />
      <path
        className="piano-key"
        d="M2167.43,100.029l2.06-0.748c-0.204,0.077-0.408,0.154-0.622,0.23C2168.395,99.682,2167.912,99.861,2167.43,100.029z"
      />
      <path
        className="piano-key"
        d="M2169.489,99.281c-0.204,0.077-0.408,0.154-0.622,0.23c-0.473,0.17-0.955,0.35-1.438,0.518
	c-2.755,1.005-5.534,2.018-8.342,3.027l-2.713,0.977l6.445,42.851l9.89-2.734l10.689-2.95l-4.934-45.174L2169.489,99.281z"
      />
      <path
        className="piano-key"
        fill="#F47B21"
        d="M2253.926,75.84c8.437-0.594,16.491-0.209,24.174,1.436c4.645,0.991,9.103,2.093,13.377,3.283l-16.49,47.812
	l10.492,5.225l-11.424,25.808c-8.793-2.952-18.298-4.964-28.564-5.798l8.441-77.713L2253.926,75.84z"
      />
      <path
        className="piano-key"
        fill="#F0A4C8"
        d="M2156.375,104.033l6.445,42.851l9.89-2.734l1.865,21.611c-13.012,4.896-26.163,10.872-39.769,17.3
	l-1.358-25.525l12.812-3.204l-5.474-44.812C2146.065,107.701,2151.262,105.865,2156.375,104.033z"
      />
      <path
        className="piano-key"
        d="M2291.527,80.572c-0.021-0.001-0.03-0.012-0.051-0.014l-16.49,47.812l10.492,5.225l14.514,7.217l24.643-43.756
	C2315.322,90.74,2304.529,85.049,2291.527,80.572z"
      />
      <path
        className="piano-key"
        fill="#A9D269"
        d="M2340.846,109.766c-4.923-4.391-10.265-8.677-16.211-12.709l-24.643,43.756l-14.514-7.217l-11.424,25.808
	c16.505,5.525,30.525,14.335,42.381,24.812l13.881-24.047l-13.892-12.307L2340.846,109.766z"
      />
      <path
        className="piano-key"
        d="M2340.846,109.766l-24.421,38.096l13.892,12.307l11.351,10.051l25.082-34.242
	C2358.789,127.64,2350.644,118.502,2340.846,109.766z"
      />
      <path
        className="piano-key"
        fill="#E19FC7"
        d="M2384.621,152.594c-6.251-4.784-12.014-10.481-17.872-16.617l-25.082,34.242l-11.351-10.051l-13.881,24.047
	c0,0,13.348,11.732,34.036,24.042L2384.621,152.594z"
      />
    </svg>
  );
};

import React from "react";
import { useQuery } from "@apollo/client";

import { Hero } from "./sections/Hero/Hero";
import { Team } from "./sections/Team";
import { Options } from "./sections/Options";
import { HOME_PAGE, Data } from "../graphql/queries/homePage";
import { Banner } from "./Banner";
import { ContactSection } from "./sections/Contact";

interface HomeProps {
  showModal(): void;
}

export const Home = ({ showModal }: HomeProps) => {
  const { data, loading, error } = useQuery<Data>(HOME_PAGE);

  if (error) {
    return <>Error: {error.message}</>;
  }

  if (loading || !data) {
    return <>Loading...</>;
  }

  const {
    homePageSectionCollection,
    teacherCollection,
    lessonOptionCollection,
    bannerCollection,
    contactDetailCollection,
  } = data;

  const whatIDo = homePageSectionCollection.items.find(
    (item) => item.slug === "hero"
  );

  const aboutMe = homePageSectionCollection.items.find(
    (item) => item.slug === "aboutMe"
  );

  const lessons = homePageSectionCollection.items.find(
    (item) => item.slug === "lessons"
  );

  const contact = homePageSectionCollection.items.find(
    (item) => item.slug === "contact"
  );

  const { teachers } = teacherCollection;
  const { options } = lessonOptionCollection;
  const { banners } = bannerCollection;
  const { contactDetails } = contactDetailCollection;

  const banner1 = banners.find((banner) => banner.slug === "banner1");
  const banner2 = banners.find((banner) => banner.slug === "banner2");
  const banner3 = banners.find((banner) => banner.slug === "banner3");

  return (
    <>
      <Hero blurb={whatIDo} showModal={showModal} />
      <Banner imageSrc={banner1?.image.url || ""} />
      <Team teachers={teachers} about={aboutMe} />
      <Banner imageSrc={banner2?.image.url || ""} />
      <Options content={lessons} options={options} />
      <Banner imageSrc={banner3?.image.url || ""} />
      <ContactSection
        showModal={showModal}
        content={contact}
        contactDetails={contactDetails}
      />
    </>
  );
};

import React from "react";

export const Quavers = ({ className }: { className?: string }) => {
  return (
    <svg
      width="595.279px"
      height="841.891px"
      viewBox="0 0 595.279 841.891"
      enableBackground="new 0 0 595.279 841.891"
      className={`h-6 w-6 ${className}`}
    >
      <path
        d="M575.04,81.635c-7.738-13.691-22.62-20.24-36.907-17.263l-343.477,64.291c-16.072,2.976-27.979,17.263-27.979,33.931
	v265.496c0,10.715-9.524,19.049-19.644,17.263c-6.548-1.19-13.691-1.786-20.835-1.786c-60.719,0-110.127,41.67-110.127,92.864
	c0,51.193,49.408,92.863,110.127,92.863s110.127-41.67,110.127-92.863V253.076c0-11.311,8.334-20.835,19.049-23.216l233.35-44.051
	c10.715-1.786,20.835,6.548,20.835,17.263v160.131c0,10.715-9.524,19.049-19.645,17.263c-6.548-1.19-13.691-1.786-20.835-1.786
	c-60.719,0-110.127,41.669-110.127,92.864s49.408,92.863,110.127,92.863s110.127-41.669,110.127-92.863V99.494
	C579.207,93.541,578.017,86.993,575.04,81.635z"
      />
    </svg>
  );
};

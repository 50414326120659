import React from "react";

export const Banner = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <div
      className="h-48 bg-bottom bg-cover"
      style={{ backgroundImage: `url(${imageSrc})` }}
    />
  );
};

import React from "react";
import { Link as ScrollLink } from "react-scroll";

import { PianoBanner } from "../../layout/PianoBanner";
import { MusicNote } from "../../layout/MusicNote";
import { Quavers } from "../../layout/Quavers";
import { HomePageSection } from "../../../types/types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richTextRenderOptions } from "../../../utils/helpers";
import { LogoSection } from "./LogoSection";

interface HeroProps {
  blurb?: HomePageSection;
  showModal(): void;
}

export const Hero = ({ blurb, showModal }: HeroProps) => {
  return (
    <main id="hero" className="mt-4 mb-16 mx-auto">
      <div className="text-center overflow-hidden">
        <LogoSection />
        <PianoBanner />
        <h1 className="text-gray-900 font-bold flex items-center justify-center my-4 sm:my-0">
          <MusicNote />
          <Quavers className="mr-4 mb-1" />
          <span className="text-3xl block xl:inline secondary-header">
            {blurb?.title}
          </span>
          <MusicNote className="mb-2 ml-2" />
          <MusicNote />
        </h1>
        <div className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          {blurb &&
            documentToReactComponents(blurb.body.json, richTextRenderOptions)}
        </div>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md flex-none shadow">
            <ScrollLink
              to="contact"
              smooth
              offset={-100}
              className="no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 hover:text-green-100 md:py-4 md:text-lg md:px-10 cursor-pointer"
            >
              Contact Us
            </ScrollLink>
          </div>
          {/* <div className="mt-3 rounded-md flex-none shadow sm:mt-0 sm:ml-3">
            <button
              onClick={showModal}
              className="no-underline w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-gray-50 hover:text-green-800 md:py-4 md:text-lg md:px-10"
            >
              Book a Free Trial Lesson
            </button>
          </div> */}
        </div>
      </div>
    </main>
  );
};
